import React from "react";
import './Header.css';

export default function Header() {
    return (
        <div className="header">
            <div className="headerContent">
                {/* <span>Election Day: </span> */}
            </div>
        </div>
    )
}